export const ITEM_STATUS_PROCESSING = 'processing';
export const ITEM_STATUS_DONE = 'completed';

export const errorMsgMap = {
  unknown: '未知的錯誤',
  'invalid encoding': '編碼錯誤，僅支援 utf-8 及 big5 編碼',
  'Invalid file type. Only CSV files are allowed.': '檔案類型無效，請上傳 csv 檔',
  'Invalid file content type. Only CSV files are allowed.': '檔案類型無效，請上傳 csv 檔',
  'record not found': '無法找到對應的紀錄',
};
