/* eslint-disable no-param-reassign */
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('token') || '',
  },
  mutations: {
    setToken(s, token) {
      s.token = token;
      localStorage.setItem('token', token);
    },
  },
  actions: {
  },
  modules: {
  },
  getters: {
    isLogin(s) {
      return !!s.token && s.token !== '';
    },
    accessToken(s) {
      return s.token;
    },
  },
});
