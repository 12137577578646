<template lang="pug">
#app.flex.column
  router-view.flex-1
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  watch: {
    $route() {
      if (!this.isLogin && this.$route.name !== 'Login') {
        this.$router.replace('/login');
      }
    },
  },
  computed: {
    ...mapGetters(['isLogin']),
  },
};
</script>

<style lang="scss">
@import "@/assets/global.scss";

html, body {
  height: 100vh;
  width: 100vw;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;

}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
